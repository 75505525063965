.app {
    position: relative;
    top: 83px;
    left: 290px;
    margin-right: 0px;
    margin-bottom: 200px;
    width: 83%;
  }

  @media screen and (max-width: 1669px) {
    
    .app {
      width: 81%;
    }
    
  }

  @media screen and (max-width: 1570px) {
    
    .app {
      width: 79%;
    }
    
  }

  @media screen and (max-width: 1400px) {
    
    .app {
      width: 77%;
    }
    
  }

  @media screen and (max-width: 1200px) {
    
    .app {
      width: 73%;
    }
    
  }

  @media screen and (max-width: 1100px) {
    
    .app {
      width: 71%;
    }
    
  }

  @media screen and (min-width: 768px) and (max-width: 1000px) {
    
    

  }

  @media screen and (max-width: 900px) {
    .app{
        left: 12px;
        width: 95%;
    }
}

@media screen and (max-width: 600px) {
  .app{
      width: 93%;
  }
}