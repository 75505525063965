.device {
  background-color: white;
  height: 52px;
  color: #3f4047;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 5px;
  margin-left: 10px;
  margin-top: 30px;
  border-radius: 2px;
  border-bottom: 1px solid #ebedf2;
  position: relative;
  padding-left: 28px;
  width: 100%;
}

.device-data {
  background-color: white;
  height: 47px;
  color: #3f4047;
  align-items: center;
  font-weight: 500;
  padding: 5px;
  margin-left: 10px;
  border-radius: 2px;
  border-bottom: 1px solid #ebedf2;
  position: relative;
  padding-left: 28px;
  padding-top: 30px;
}

.device-data span {
  justify-content: space-around;
  display: flex;
  padding: 5px;
  font-size: 14px;
  color: #a6a7c1;
  font-weight: 600;
}

.select-div {
  background-color: white;
  width: 100%;
  padding: 15px 17px 20px 0px;
}



@media screen and (max-width: 900px) {
  .select-div {
    display: flex;
    flex-direction: column;
  }
}
