.device-styles {
    background-color: white;
    border-right: 1px solid #ebedf2;
    border-bottom: 1px solid #ebedf2;
    padding: 15px !important;
}

.device-styles:hover {
    background-color: #dde0e6;
}

.t-style {
    width: 45px;
    height: 45px;
    margin: 15px 30px;
    text-align: center;
}

.display-none{
    display: none;
}

.align-items {
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #575962;
    font-size: 14px;
    font-weight: 300;
    font-family: 'Poppins';
}

.download-csv-icon-style {
    color: #575962;
    padding: 6px;
    border-radius: 15px;
    border: 1px solid #eff1f5;
}

.download-csv-icon-style:hover {
    background-color: #c2c5cb;
    transition: 0.3s ease;
}
.download-pdf-icon-style {
    color: #575962;
    padding: 6px;
    margin-right: 3px;
    border-radius: 15px;
    border: 1px solid #eff1f5;
}

.download-pdf-icon-style:hover {
    background-color: #c2c5cb;
    transition: 0.3s ease;
}

.devices-padding {
    /* padding: 30px; */
    background-color: white;
}

.paginate-div-style-devices {
    padding-right: 20px;
    display: flex;
    justify-content: right;
    background-color: white;
  }

.device-title {
    font-size: 20px;
    padding: 0 15px;
    color: #575962;
    font-family: 'Poppins';
    font-weight: 600;
}

.device-date {
    color: #575962;
    font-size: 13px;
    font-weight: 300;
    font-family: 'Poppins';
}

.device-temp-style {
    font-size: 28px;
    font-weight: bold;
    color: #575962;
    font-family: 'Poppins';
    text-align: center !important;
}

.device-status {
    cursor: pointer;
    text-decoration: none;
    color: #575962;
    font-size: 13px;
    font-family: 'Poppins';
    margin: 20px 0;
}

.download-style {
    cursor: pointer;
    text-align: center;
}