.footer-style {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 255px;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Poppins';
    color: #a9a9aa;
}

@media screen and (max-width: 900px) {
    .footer-style{
        left: 0;
    }
}