.header-style {
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    background-color: #201f2b;
    width: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
}

.user-img-style {
    width: 40px;
    border-radius: 30px;
}

.user-img-style:hover {
  cursor: pointer;
}

.user-img-div {
    position: absolute;
    right: 30px;
    height: 60px;
    width: 60px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-img-div:hover {
    background-color: #2d2c3d;
    cursor: pointer;
}

@media screen and (max-width:1300px) {
    .user-img-div{
        right: 15px;

    }
}

.burger {
    display: none;
    cursor: pointer;
  }
  
  .burger div {
    width: 25px;
    height: 4px;
    background-color: #666575;
    margin: 5px;
    transition: all .3s ease;
  }

  @media screen and (max-width:900px) {
    .burger {
      display: block;
      margin-left: 10px;
    }
  }