.add-button-style {
    cursor: pointer;
    background-color: #716aca;
    border-color: #716aca;
    color: #fff;
    height: 40px;
    border-radius: 5px;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 400;
    padding: 10px 25px;
    display: flex;
    align-items: center;
}

.add-button-style:hover {
    background-color: #564ec0;
}
