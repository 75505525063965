.select-style {
    height: 40px;
    width: 15%;
    border: 1px solid;
    border-radius: 5px;
    margin-right: 10px;
    margin-left: 10px;
    padding-left: 10px;
  }
  
  .select-style:focus {
    border-color: #716aca;
  }

  .center-itemss {
    display: flex;
    align-items: center;
  }

.change-status-button {
    border: 1px solid #dddee3;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    padding: 1px 2px 1px 2px;
    width: 85px;
}

.edit-button {
  border: 1px solid #716aca;
  border-radius: 5px;
  color: #fff;
  background-color: #716aca;
  cursor: pointer;
  padding: 1px 2px 1px 2px;
  width: 65px;
  margin-left: 5px;
}

.edit-button:hover {
  background-color: #564ec0;
}

.aktiviraj {
    color: #00c5dc;
    font-weight: 500;
}

.deaktiviraj {
    color: #f4516c;
    font-weight: 500;
}

.deaktiviraj:hover {
    background-color: #f4516c;
    color: white;
    transition: 0.3s ease;
}

.aktiviraj:hover {
    background-color: #00c5dc;
    color: white;
    transition: 0.3s ease;
}
  
  .groups-select-style {
    background-color: white;
    width: 80%;
    margin-left: 10px;
    padding: 20px;
  }
  
  @media screen and (max-width: 900px) {
    .select-style {
      width: 50%;
      margin-bottom: 10px;
    }
  }
  
  @media screen and (max-width: 700px) {
      .select-style {
        width: 90%;
        margin-bottom: 10px;
      }
    }

    .button-style {
        cursor: pointer;
        color: #fff;
        font-family: 'Poppins';
        background-color: #716aca;
        border-color: #716aca;
        height: 40px;
        width: 80px;
        margin-left: 10px;
        border-radius: 5px;
    }
    
    .span-button-style {
        font-size: 15px;
        background-color: white;
        width: 80%;
    }
    
    .button-style:hover {
        background-color: #564ec0;
    }
    
    @media screen and (max-width:900px) {
        .button-style {
            width: 50%;
            margin-top: 15px;
        }
    }