.subtitle { 
    background-color: white;
    height: 60px;
    color: #575962;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 5px;
    margin-top: 30px !important;
    border-radius: 2px;
    border-bottom: 1px solid #ebedf2;
    position: relative;
    padding-left: 28px;
    width: 100%;
    font-size: 18px;
  }

  .add-button-position {
    position: absolute;
    right: 20px;
  }