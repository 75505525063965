.dropdown-style {
    height: 100vh;
    width: 255px;
    background-color: #282733;
    left: 0;
    padding-top: 82px;
    position: fixed;
    z-index: 1;
    margin-right: 20px;
}

.dropdown-style-mobile {
    height: 100vh;
    width: 255px;
    background-color: #282733;
    left: 0;
    padding-top: 70px;
    position: fixed;
    z-index: 1;
    margin-right: 20px;
    display: none;
}

.dropdown-style a {
    color: #868aa8;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 35px;
}

.dropdown-style-mobile a {
    color: #868aa8;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 35px;
}

.link-style {
    color: white;
    text-decoration: none;
    font-size: 14px;
}

nav a.active {
    background-color: #f12742 !important;
    color: white !important;
}

nav a.active .icons-style {
    color: white !important;
}

.icons-style {
    color: #868aa8 !important;
    margin-right: 15px;
}

.icons-style-active {
    color: #fff !important;
    margin-right: 15px;
}

.link-style:hover {
    background-color: #2d2c3d;
    color: white;
    transition: 0.3s ease;
}

.none {
    display: none;
}


@media screen and (max-width: 900px) {

    .dropdown-style-mobile {
        height: 100vh;
        width: 255px;
        background-color: #282733;
        left: 0;
        padding-top: 70px;
        position: fixed;
        z-index: 1;
        margin-right: 20px;
        display: block;
    }
    .dropdown-style {
        display: none;
    }

} 
