.thead-style-content {
    border: 2px solid #f4f5f8;
    padding: 3px 8px 3px 8px;
    text-align: l;
    color: #575962;
    font-weight: 400;
    font-size: 14px;
}

.thead-style-header {
    border: 2px solid #dddee3;
    padding: 8px;
    background-color: #f2f5ff;
    text-align: left;
    color: #575962;
    font-weight: 500;
    font-size: 14px;
}

.groups-table-style {
    background-color: white;
    width: 100%;
    padding: 50px 20px 20px 20px;
}

.edit-button {
    border: 1px solid #716aca;
    border-radius: 5px;
    color: #fff;
    background-color: #716aca;
    cursor: pointer;
    padding: 1px 2px 1px 2px;
    width: 65px;
    margin-left: 5px;
  }
  
  .edit-button:hover {
    background-color: #564ec0;
  }

.tr-style:hover {
    background-color: #e8ebf6;
}

.users-table-style {
    background-color: white;    
    padding: 50px 20px 20px 20px;
}

table {
    border-spacing: 0px;
}

.info-table-style {
    background-color: white;
    padding: 15px 16px 15px 17px;
    font-size: 14px;
    color: #a6a7c1;
    font-weight: 600;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
}

.center-items {
    display: flex;
    align-items: center;
  }

.change-status-button {
    border: 1px solid #dddee3;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    padding: 1px 2px 1px 2px;
    width: 85px;
}

.aktiviraj {
    color: #00c5dc;
    font-weight: 500;
}

.deaktiviraj {
    color: #f4516c;
    font-weight: 500;
}

.deaktiviraj:hover {
    background-color: #f4516c;
    color: white;
    transition: 0.3s ease;
}

.aktiviraj:hover {
    background-color: #00c5dc;
    color: white;
    transition: 0.3s ease;
}

.actions-icon {
    margin-left: 5px;
    background: 0 0;
    padding: 10px;
    border-radius: 20px;
    color: #575962;
    cursor: pointer;
}

.actions-icon:hover {
    border-color: #716aca!important;
    color: #fff!important;
    background-color: #716aca!important;
    transition: 0.3s ease;
}

.status-change {
    position: relative;
    border: 1px solid #dddee3;
    background-color: #e4e5ea;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
}

.status-change:hover {
    background-color: #dadde7;
}

@media screen and (max-width: 900px) {
    .groups-table-style {
        padding: 50px 0px 20px 0px;
        margin-left: 0px;
    }

    .info-table-style {
        padding: 15px 10px 15px 10px;
        overflow: auto;
    }

    .thead-style {
        width: 150px;
    }

    .thead-style-header {
        width: 150px;
    }

    .status-change {
        right: 0;
    }
}

.table-style {
    background-color: white;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    padding: 15px 16px 15px 17px;
    overflow: auto;
}

.device-table-info {
    justify-content: center;
    align-items: center;
    border: 1px solid #f4f5f8;
    height: 60px;
    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 600;
    color: #575962;
    padding: 0 15px 0 15px;

}

.active-user {
    color: #00c5dc!important;
}

.non-active-user {
    color: #f4516c!important;
}

.font {
    font-weight: 100;
}


table {
    border-spacing: 0px;
    table-layout: fixed;
    width: inherit;
}

.content-style {
    word-break: normal;
}

.pagination {
    justify-content: right;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 15px;
    gap: 5px;
}

.pagination .page-num {
    color: #575962;
    text-decoration: none;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 600;
    background-color: #eff1f5;
}

.pagination .page-num:hover {
    background-color: #716aca;
    color: #fff;
}

.pagination .active-page {
    background-color: #716aca;
    color: #fff;
}

.paginate-div-style {
    display: flex;
    justify-content: right;
}

@media screen and (max-width:900px) {
    .device-table-info{
        padding-left: 5px;
        padding-right: 5px;
    }

    .paginate-div-style {
        justify-content: left;
    }
}

@media screen and (max-width:1100px) {
    .device-table-info{
        padding-left: 5px;
        padding-right: 5px;
        width: 100px;
    }
}
