.user-card-style {
  background-color: rgb(244, 244, 244);
  width: 320px;
  height: 130px;
  position: fixed;
  top: 65px;
  right: 10px;
  z-index: 100;
  display: flex;
  align-items: center;
  border-radius: 10px;
  transition: transform 0.2s ease-in;
}

.logout-icon-style {
    border-radius: 5px;
    color: gray;
    height: 40px;
    width: 40px;
}

.img-padding {
  padding-left: 10px;
}

.username-padding {
  padding-left: 10px;
}

.none {
    display: none !important;
}

.profile-style-div {
  padding: 12px 10px;
}

.profile-style-div:hover {
  background-color: #dcdddf;
  border-radius: 10px;
}

.logout-style-div {
  display: flex;
  padding: 12px 10px;
  align-items: center;
}

.logout-style-div:hover {
  cursor: pointer;
  border-radius: 10px;
  background-color: #dcdddf;
}

.row {
   --bs-gutter-x: 0 !important;
}

.profile-button-style {
  width: 100%;
  border-radius: 15px;
  border: 1px solid #716aca;
  background-color: #716aca;
  cursor: pointer;
  padding: 10px;
  color: #fff;
}

.profile-button-style:hover {
  background-color: #564ec0;
}

.link-profile-style {
  color: #212529;
  text-decoration: none;
  font-size: 14px;
  align-items: center;
    display: flex;
}

.link-profile-style:hover {
  color: #212529;
}

.logout-button-style {
  width: 100%;
  border-radius: 15px;
  background-color: #716aca;
    border-color: #716aca;
    color: #fff;
  cursor: pointer;
  padding: 10px;
}

.logout-button-style:hover {
  background-color: #564ec0;
}